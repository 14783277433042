import React, { useContext } from 'react';
import Layout from "../components/layout";
import badgePath from "../img/secondPage/icons/badge.svg";
import alertPath from "../img/secondPage/icons/alert.svg";
import chartPiePath from "../img/secondPage/icons/chart-pie.svg";
import targetPath from "../img/secondPage/icons/target.svg";
import windowPath from "../img/secondPage/icons/window-paragraph.svg";
import stockPath from "../img/secondPage/icons/stock.svg";
import boardPath from "../img/secondPage/icons/board.svg";
import HandshakePath from "../img/secondPage/icons/handshake.svg";
import { ModalContext } from '../context/modalContext';
import { graphql } from "gatsby";
import Img from 'gatsby-image';
import Fade from 'react-reveal/Fade';
import SEO from '../components/seo';

export default ({data}) => {
  const { onHide } = useContext(ModalContext)
  return (
    <Layout>
			<SEO title="How it works" image="/img/HowItWorks.png" description="Working with Fennec is a simple 3 step process. Find out how easy it is!" />
      <div className="section21 text-center" id="HowItWorks">
        <div className="para211group">
          <Fade top distance={'10%'} duration={2500}>
            <div>
              <h3 className="para11 font-f5 font-sz-28" data-1 >{data.fennecJson.howItWorks.data1}</h3>
              <h1 className="font-f6 font-sz-75" data-2 >{data.fennecJson.howItWorks.data2}</h1>
            </div>
          </Fade>
        </div>
        <div className="section2img1Wrapper">
          <Img className="section2img1" fluid={data.howItWorks.childImageSharp.fluid} />
        </div>
        <Fade cascade>
          <div className="para212group" id="introduceYourself">
            <p className="para13 font-f5 font-sz-28">- 1 -</p>
            <p className="para14 font-f4 font-sz-60" data-3 >{data.fennecJson.howItWorks.data3}</p>
            <p className="para15 font-more font-sz-20" data-4 >{data.fennecJson.howItWorks.data4}</p>
          </div>
        </Fade>
        <div className="row justify-content-center cardsGroup">
          <Fade>
            <div className="col-12 col-md-5 cards">
              <div className="col-12 cardContainer">
                <img src={badgePath} alt="" className="cardIcon cardIcon1 d-inline" />
                <p className="para16 d-inline font-f4 font-sz-22">Team CV</p>
              </div>
            </div>
            <div className="col-12 col-md-5 cards">
              <div className="col-12 cardContainer">
                <img src={alertPath} alt="" className="cardIcon cardIcon2 d-inline" />
                <p className="para16 d-inline font-f4 font-sz-22">Problem description</p>
              </div>
            </div>
            <div className="col-12 col-md-5 cards">
              <div className="col-12 cardContainer">
                <img src={chartPiePath} alt="" className="cardIcon cardIcon3 d-inline" />
                <p className="para16 d-inline font-f4 font-sz-22">Value proposition</p>
              </div>
            </div>
            <div className="col-12 col-md-5 cards">
              <div className="col-12 cardContainer">
                <img src={targetPath} alt="" className="cardIcon cardIcon4 d-inline" />
                <p className="para16 d-inline font-f4 font-sz-22">Addressable market</p>
              </div>
            </div>
            <div className="col-12 col-md-5 cards">
              <div className="col-12 cardContainer">
                <img src={windowPath} alt="" className="cardIcon cardIcon5 d-inline" />
                <p className="para16 d-inline font-f4 font-sz-22">Competitive analysis</p>
              </div>
            </div>
            <div className="col-12 col-md-5 cards">
              <div className="col-12 cardContainer">
                <img src={stockPath} alt="" className="cardIcon cardIcon6 d-inline" />
                <p className="para16 d-inline font-f4 font-sz-22">Benchmark analysis</p>
              </div>
            </div>
            <div className="col-12 col-md-5 cards">
              <div className="col-12 cardContainer">
                <img src={boardPath} alt="" className="cardIcon cardIcon7 d-inline" />
                <p className="para16 d-inline font-f4 font-sz-22">Financials</p>
              </div>
            </div>
            <div className="col-12 col-md-5 cards">
              <div className="col-12 cardContainer">
                <img src={HandshakePath} alt="" className="cardIcon cardIcon8 d-inline" />
                <p className="para16 d-inline font-f4 font-sz-22">And then we’ll sign contracts</p>
              </div>
            </div>
          </Fade>
        </div>
      </div>
      <div className="section22 row" id="readOurProposal">
        <Fade duration={2000}>
          <div className="paraWrapper2">
            <p className="para21 font-f5 font-sz-28">- 2 -</p>
            <p className="para22 font-f4 font-sz-60" data-5 >{data.fennecJson.howItWorks.data5}</p>
            <p className="para23 font-more font-sz-20" data-6 >{data.fennecJson.howItWorks.data6}</p>
          </div>
        </Fade>
      </div>
      <div className="section23 row" id="contract">
        <Fade duration={2000}>
          <div className="paraWrapper3">
            <p className="para21 font-f5 font-sz-28">- 3 -</p>
            <p className="para22 font-f4 font-sz-60" data-7 >{data.fennecJson.howItWorks.data7}</p>
            <p className="para23 font-more font-sz-20" data-8 >{data.fennecJson.howItWorks.data8}</p>
          </div>
        </Fade>
      </div>
      <div className="section24 row" id="startOfWork">
        <Fade duration={2000}>
          <div className="paraWrapper4">
            <p className="para21 font-f5 font-sz-28">- 4 -</p>
            <p className="para22 font-f4 font-sz-60" data-9 >{data.fennecJson.howItWorks.data9}</p>
            <p className="para23 font-more font-sz-20" data-10 >{data.fennecJson.howItWorks.data10}</p>
          </div>
        </Fade>
      </div>
      <div className="section25">
        <div className="paraWrapper5">
          <p className="para51 font-f4 font-sz-60" data-11 >{data.fennecJson.howItWorks.data11}</p>
          <button className="button51 font-f6 font-sz-16" onClick={() => onHide(true)}>GET IN TOUCH</button>
        </div>
      </div>
    </Layout>
  )
}


export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    howItWorks: file(relativePath: { eq: "secondPage/howItWorks.png" }) {
      ...fluidImage
    }
    fennecJson {
      howItWorks {
       data1,
       data2,
       data3,
       data4,
       data5,
       data6,
       data7,
       data8,
       data9,
       data10,
       data11
      }
    }
  }
`;

